import React from "react";
import {
  ArrowIcon,
  FacebookIcon,
  FireIcon,
  XIcon,
  YoutubeIcon,
} from "../../components/icons";
import { Avatar } from "../../components/Avatar";
import Avatar1 from "../../img/Avatar1.png";
import Avatar2 from "../../img/Avatar2.png";
import Avatar3 from "../../img/Avatar3.png";

export const LandingPage = ({ setIndex }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex gap-2 items-center mt-8">
        <FireIcon />
        <p className="text-[#69696A] text-[18px]">
          Generate Cold Email Sequences
        </p>
      </div>
      <div className="flex justify-center">
        <p
          className="text-[60px] text-white w-[80%] text-center font-extrabold"
          style={{
            background: "linear-gradient(to right, #FFFFFF, #545353)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          Generate Cold Emails in less than 1 minute
        </p>
      </div>
      <div className="mt-4">
        <p className="text-[16px] text-white text-center">
          Cold Email Copy Generator is a tool to help you generate Cold Emails.
          We know the pain and we understand the problem.
        </p>
        <p className="text-[16px] text-white text-center">
          Click the "Generate" button below, we've build a custom AI model and
          trained it on 1M+ subject lines from millions of campaigns.
        </p>
      </div>
      <div className="mt-8 flex gap-4">
        <button
          className="text-white border-[1px] border-[#4F5054]  rounded-lg flex gap-2 items-center justify-center px-3 py-1"
          onClick={() => setIndex(1)}
        >
          <span className="text-[20px]">Generate</span>
          <ArrowIcon />
        </button>
      </div>
      <div className="mt-8 flex gap-4 items-center">
        <div className="flex">
          <Avatar isMargin={false} img={Avatar1} />
          <Avatar isMargin={true} img={Avatar2} />
          <Avatar isMargin={true} img={Avatar3} />
        </div>

        <p className="text-[16px] text-[#6B7280]">
          Join 20,000+ others who generated the perfect cold email
        </p>
      </div>
      <div className="flex items-center mt-4 gap-3">
        <FacebookIcon />
        <YoutubeIcon />
        <XIcon />
      </div>
    </div>
  );
};
