import React, { useState } from "react";
import { Spin } from "antd";

export const FunctionalPage = () => {
  const [formDetails, setFormDetails] = useState({
    companyName: null,
    service: null,
    targetIndustries: null,
    jobTitles: null,
    avatar: null,
    special: null,
    credibility: null,
  });
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateText = async () => {
    setResponse("");
    setIsLoading(true);
    if (
      formDetails.companyName === null ||
      formDetails.service === null ||
      formDetails.targetIndustries === null ||
      formDetails.jobTitles === null ||
      formDetails.avatar === null ||
      formDetails.special === null ||
      formDetails.credibility === null
    ) {
      alert("Please answer all the mandatory questions");
    } else {
      const OPENAI_API_KEY =
        "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";
      const apiUrl = "https://api.openai.com/v1/chat/completions";
      const requestData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: `Generate a cold email for a company called ${formDetails.companyName} and has the following info:
              1. The service/product it sells:${formDetails.service}
              2. The target industries:${formDetails.targetIndustries}
              3. Target Jobtitles: ${formDetails.jobTitles}
              4. Customer Avatar: ${formDetails.avatar}
              5. What makes us special: ${formDetails.special}
              6. Credibility/Authorirty: ${formDetails.credibility}
              Make it short and easy to use, no more than 70 words.
              `,
            },
          ],
        }),
      };
      try {
        const response = await fetch(apiUrl, requestData);
        const data = await response.json();
        setResponse(data.choices[0].message.content);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center mt-4 justify-center">
      {isLoading ? <Spin fullscreen /> : <></>}
      <div className="flex gap-[4rem] mt-6 justify-between w-full px-[13rem]">
        <LeftComponent
          setFormDetails={setFormDetails}
          formDetails={formDetails}
          generateText={generateText}
        />
        <RightComponent data={response} />
      </div>
    </div>
  );
};

const LeftComponent = ({ setFormDetails, formDetails, generateText }) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <InputComponent
        header="Company Name"
        formDetails={formDetails}
        setFormDetails={setFormDetails}
        fieldKey="companyName"
        placeholder={"Your company name..."}
      />

      <div className="flex flex-col gap-1">
        <p className="text-white text-[14px]">
          What service or product do you sell?
          <span className="text-blue-600"> *</span>
        </p>
        <textarea
          placeholder={
            "We sell social media marketing services where we manage facebook asd, google ads, and content creation"
          }
          onChange={(ev) =>
            setFormDetails({ ...formDetails, service: ev.target.value })
          }
          className="bg-[#232529] rounded-md px-3 py-1 text-white h-[3.5rem] placeholder:text-gray-500"
        />
      </div>

      <InputComponent
        header="List all the industries to target"
        formDetails={formDetails}
        setFormDetails={setFormDetails}
        fieldKey="targetIndustries"
        placeholder={"Marketing Agencies, Content Agencies...etc"}
      />

      <InputComponent
        header="List all job titles that you want the email to target"
        formDetails={formDetails}
        setFormDetails={setFormDetails}
        fieldKey="jobTitles"
        placeholder={"Founder, CMO, Owner, President"}
      />

      <div className="flex flex-col gap-1">
        <p className="text-white text-[14px]">
          Customer Avatar
          <span className="text-blue-600"> *</span>
        </p>
        <textarea
          placeholder={
            "My ideal customer is a TITLE at a CATEGORY Company with at least x Employees"
          }
          onChange={(ev) =>
            setFormDetails({ ...formDetails, avatar: ev.target.value })
          }
          className="bg-[#232529] rounded-md px-3 py-1 text-white h-[3.5rem] placeholder:text-gray-500"
        />
      </div>

      <InputComponent
        header="What makes YOU special?"
        formDetails={formDetails}
        setFormDetails={setFormDetails}
        fieldKey="special"
        placeholder={
          "We guarantee X result in x days / We are endorsed by Dwayne Johnson"
        }
      />

      <div className="flex flex-col gap-1">
        <p className="text-white text-[14px]">
          Credibility/authority (testimonials, awards, rankings)
          <span className="text-blue-600"> *</span>
        </p>
        <textarea
          placeholder={
            "We won the Super Business award for marketing. We were featured in USA Grammys for business as the top 10 agencies in San Francisco"
          }
          onChange={(ev) =>
            setFormDetails({ ...formDetails, credibility: ev.target.value })
          }
          className="bg-[#232529] rounded-md px-3 py-1 text-white h-[3.5rem] placeholder:text-gray-500"
        />
      </div>

      <button
        className="bg-[#232529] rounded-md px-3 py-1 text-white"
        onClick={() => generateText()}
      >
        Generate
      </button>
    </div>
  );
};

const RightComponent = ({ data }) => {
  return (
    <div className="w-full h-[36.8rem]">
      <p className="text-white text-[14px]">
        High Converting Cold Email Copy Below ⬇️
      </p>
      <textarea
        value={data}
        className="bg-[#232529] rounded-md px-3 py-1 mt-1 text-white h-full w-full placeholder:text-gray-500"
        disabled
      ></textarea>
    </div>
  );
};

function InputComponent({
  header,
  formDetails,
  setFormDetails,
  fieldKey,
  placeholder,
}) {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-white text-[14px]">
        {header}
        <span className="text-blue-600"> *</span>
      </p>
      <input
        placeholder={placeholder}
        onChange={(ev) =>
          setFormDetails({ ...formDetails, [fieldKey]: ev.target.value })
        }
        className="bg-[#232529] rounded-md px-3 py-1 text-white placeholder:text-gray-500"
      />
    </div>
  );
}
