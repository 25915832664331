import React, { useState } from "react";
import { LandingPage } from "./Pages/LandingPage/inedx";
import { FunctionalPage } from "./Pages/FunctionalPage";
import "./App.css";

function App() {
  const [index, setIndex] = useState(0);

  return (
    <div>
      {index === 0 ? <LandingPage setIndex={setIndex} /> : <FunctionalPage />}
    </div>
  );
}

export default App;
