import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <title>Cold Email Generator - Generate Cold Email via AI</title>
      <meta
        name="description"
        content="Streamline your outreach strategy with our advanced Cold Email Generator. Craft personalized, effective emails at scale to engage prospects and drive conversions. Explore our tool now for efficient and impactful communication."
      />
      <meta
        name="keywords"
        content="ai cold email generator, cold email generator, cold email ai generator, cold email generator ai"
      />
    </Helmet>
    <App />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
