import React from "react";

export const Avatar = ({ img, isMargin }) => {
  return (
    <div
      className="rounded-full w-8 aspect-square bg-red-500"
      style={{ marginLeft: isMargin ? "-0.75rem" : "" }}
    >
      <img src={img} alt="" className="w-full h-full" />
    </div>
  );
};
